/**
 * @license
 * Copyright 2024 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-transfer-function-panel {
  height: 60px;
  border: 1px solid #666;
  margin-top: 5px;
}

.neuroglancer-transfer-function-color-picker {
  text-align: right;
}

.neuroglancer-transfer-function-widget-bound {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  border: 0;
  margin: 0;
  font-family: monospace;
  font-size: medium;
  color: cyan;
}

.neuroglancer-transfer-function-window-bounds {
  display: flex;
  justify-content: space-between;
}
